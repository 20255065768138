<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Управление заказами</v-card-title>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="8"
            class="text-right"
          >
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="this.items"
          :loading="loading"
          dense
          disable-pagination
          hide-default-footer
          item-key="id"
          sort-by="id"
          sort-desc
        >
          <template #[`item.created`]="{ item }">
            <router-link :to="{ name: 'OrderAdminEdit', params: { id: item.id } }">
              {{ item.created | dateformat }}
            </router-link>
          </template>
          <template #[`item.amount`]="{ item }">
            {{ item.amount | currency(`USD`) }}
          </template>
          <template #[`item.note`]="{ item }">
            <v-tooltip
              v-if="item.note"
              bottom
              max-width="400"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="blue"
                  v-on="on"
                >
                  mdi-comment
                </v-icon>
              </template>
              <span>{{ cleanNote(item.note) }}</span>
            </v-tooltip>
            <v-icon
              v-else
              color="gray"
            >
              mdi-comment
            </v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import {mapGetters} from "vuex";
import stocks from "@/api/stocks";

export default {
  name: "OrderList",
  data: () => ({
    items: [],

    loading: true,
    isShowAllOrders: false,
  }),
  computed: {
    ...mapGetters("clients", ["getUserCompany"]),
    ...mapGetters("auth", ["isMarketplaceManager"]),
    headers() {
      let headers = [
        {text: "№", value: "id"},
        {text: "Дата", value: "created"},
        {text: "Склад", value: "stock_to", sortable: false, isMarketplaceManager: false},
        {text: "Склад назначения", value: "stock_to", sortable: false, isMarketplaceManager: true},
        {text: "Тип заказа", value: "type", isMarketplaceManager: true},
        {text: "Компания", value: "company", sortable: false, isMarketplaceManager: false},
        {text: "Сумма", value: "amount"},
        {text: "Статус", value: "status_display"},
        {text: "Статус оплаты", value: "payment_status_display", isMarketplaceManager: false},
      ];
      return this.isMarketplaceManager
        ? headers.filter((el) => el.isMarketplaceManager !== false)
        : headers.filter((el) => el.isMarketplaceManager !== true);
    },
  },
  watch: {
    getUserCompany() {
      this.updateAllOrders();
    },
  },
  created() {
    this.updateAllOrders();
  },
  methods: {
    updateAllOrders() {
      stocks.getAdminOrderList().then((response) => {
        this.items = response.data;
        this.loading = false;
      });
    },
    cleanNote(note) {
      return note.replace(/(<([^>]+)>)/g, "");
    },
  },
};
</script>
